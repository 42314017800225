import React from 'react'

import { ReactComponent as HrIcon } from '../../images/categories/hr.svg'
import { ReactComponent as ProjectIcon } from '../../images/categories/project.svg'
import { ReactComponent as FinanceIcon } from '../../images/categories/finance.svg'
import { ReactComponent as SalesIcon } from '../../images/categories/sales.svg'
import { ReactComponent as EducationIcon } from '../../images/categories/education.svg'
import { ReactComponent as MarketingIcon } from '../../images/categories/marketing.svg'
import { ReactComponent as InventoryIcon } from '../../images/categories/inventory.svg'
import { ReactComponent as ManufacturingIcon } from '../../images/categories/manufacturing.svg'


export const categories = [
    'Inventory',
    'Manufacturing',
    'Project management',
    'Finance',
    'Sales',
    'Marketing',
    'Human resources',
    'Education',
    'Other'
]

export const categoryProperties = (category) => {
    switch (category) {
        case 'Inventory':
            return {
                color: '#0074ff',
                colorLight: '#acbdfc',
                icon: <InventoryIcon />
            }
        case 'Manufacturing':
            return {
                color: '#06c1bb',
                colorLight: '#afe9e7',
                icon: <ManufacturingIcon />
            }
        case 'Project management':
            return {
                color: '#f95357',
                colorLight: '#ffaac4',
                icon: <ProjectIcon />
            }
        case 'Finance':
            return {
                color: '#24ce70',
                colorLight: '#4be88e',
                icon: <FinanceIcon />
            }
        case 'Sales':
            return {
                color: '#00b6ff',
                colorLight: '#80c0e9',
                icon: <SalesIcon />
            }
        case 'Marketing':
            return {
                color: '#8e50ef',
                colorLight: '#975df4',
                icon: <MarketingIcon />
            }
        case 'Education':
            return { 
                color: '#f27520',
                colorLight: '#f4a269',
                icon: <EducationIcon />
            }
        case 'Human resources':
            return {
                color: '#ffc740',
                colorLight: '#ffc640',
                icon: <HrIcon />
            }
        default:
            return undefined
    }
}

export const workflowSettings = {
    nameMaxLength: 80,
    descriptionMaxLength: 140,
    categoryMaxLength: 80
}
