import React, { useState, useEffect } from 'react'
import { toArray } from 'lodash'
import * as Teams from '../../../services/Teams'

import {
    Typography,
    List
} from '@material-ui/core'

import { categoryProperties } from '../../../constants/workflow'
import { getColorFromString } from '../../../services/colors'

import CreateWorkflow from '../CreateWorkflowButton'
import ListWorkflowItem from '../ListWorkflowItem'
import WorkflowIcon from '../WorkflowIcon'
import { MSAppGTM } from '../../../services/utils'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    root: {
        height: '100%',
        margin: 1
    },
    list: {
        height: '100%',
        overflow: 'auto'
    },
    title: {
        padding: 15,
        fontSize: '1.2rem',
        fontFamily: 'ProximaNovaWebBold, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1.167,
    }
})

const ChoiceWorkflow = ({ workflows }) => {
    const classes = useStyles()

    const [workflowSelected, setWorkflowSelected] = useState(null)

    const handleOpenWorkflow = (id) => {
        MSAppGTM(`Click Choice Workflow Button: ID: ${id}`)

        Teams.init()
        setWorkflowSelected(id)
        Teams.saveWorkflowId(id)
    }

    useEffect(() => {
        MSAppGTM('MS Teams Choice Workflow Page')
    }, [])

    return (
        <>
            {toArray(workflows).length !== 0 ? (
                <>
                    <Typography
                        className={classes.title}
                    >
                        Select one workflow to attach to your channel or chat
                </Typography>
                    <List
                        className={classes.list}
                        disablePadding
                    >
                        {toArray(workflows).map(w => (
                            <ListWorkflowItem
                                key={w.id}
                                id={w.id}
                                primary={w.name}
                                secondary={
                                    w.users.length > 1
                                        ? `${w.users.length} Team members`
                                        : '1 Team member'
                                }
                                icon={(
                                    <WorkflowIcon
                                        name={w.name}
                                        category={w.category}
                                    />
                                )}
                                color={categoryProperties(w.category)
                                    ? categoryProperties(w.category).color
                                    : getColorFromString(w.name)
                                }
                                active={(workflowSelected === w.id)}
                                onClick={handleOpenWorkflow}
                            />
                        ))}
                    </List>
                </>
            ) : (
                <CreateWorkflow />
            )}
        </>
    )
}

export default ChoiceWorkflow
