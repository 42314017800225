import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Api from '../../../services/Api'

import {
    Grid,
    Typography,
    FormControlLabel,
    Checkbox,
    Button
} from '@material-ui/core'

import { useSession } from '../../../Context'

import LinkStyled from '../LinkStyled'
import useStyles from './styles'

const SignUp = ({ userData, onFinish }) => {
    const classes = useStyles()

    const [acceptTerms, setAcceptTerms] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const { state: { session } } = useSession()

    const handleToggleAcceptTerms = (e) => {
        setAcceptTerms(e.target.checked)
    }

    const handleSignUp = () => {
        if (acceptTerms) {
            setLoading(true)
            const payload = {
                "isAddin": true,
                "partner": null
            }
            const SheetgoAPI = new Api({ token: session.token })
            SheetgoAPI.signUp({ ...payload, ...userData }, (user_token) => {
                setLoading(false)
                if (user_token) {
                    onFinish(user_token)
                }
            })
        }
    }

    return (
        <Grid
            direction="column"
            alignItems="center"
            justify="center"
            container
        >
            <Grid item>
                <Typography variant="h1" className={classes.title} gutterBottom>
                    Welcome to Sheetgo
                </Typography>
            </Grid>
            <Grid className={classes.bodyTextContainer} item>
                <Typography variant="body2" className={classes.bodyText}>
                    <span>By using this application, you agree to the </span>
                    <LinkStyled
                        href="https://www.sheetgo.com/legal/terms/"
                        target="_blank"
                        variant="primary"
                    >
                        Terms of Service
                    </LinkStyled>
                    <span> and </span>
                    <LinkStyled
                        href="https://www.sheetgo.com/legal/privacy/"
                        target="_blank"
                        variant="primary"
                    >
                        Privacy Policy
                    </LinkStyled>
                    <span>
                        . Click on the checkbox below to indicate that you understand
                        our conditions of usage.
                    </span>
                </Typography>
            </Grid>
            <Grid
                alignItems="center"
                direction="column"
                justify="center"
                container
                item
            >
                <FormControlLabel
                    className={classes.checkboxContainer}
                    control={(
                        <Checkbox
                            checked={acceptTerms}
                            onChange={handleToggleAcceptTerms}
                            color="primary"
                        />
                    )}
                    label="I agree to the Sheetgo Terms of Service and Privacy Policy"
                />
                <Button
                    className={classes.signUpButton}
                    variant="contained"
                    color="primary"
                    onClick={handleSignUp}
                    disabled={!acceptTerms || isLoading}
                >
                    Sign up
                </Button>
            </Grid>
        </Grid>
    )
}

SignUp.propTypes = {
    props: PropTypes.object.isRequired
}

export default SignUp