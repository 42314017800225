import React, { useEffect } from 'react'
import * as msTeams from '@microsoft/teams-js'
import * as Teams from '../../services/Teams'

import {
    Grid,
    Typography
} from '@material-ui/core'

import { ReactComponent as TeamsRemoveFigure } from '../../images/microsoft-teams-remove-tab.svg'
import { SHEETGOSUPPORT } from '../../constants/links'
import useStyles from './styles'

const Remove = () => {
    const classes = useStyles()

    useEffect(() => {
        msTeams.initialize()
        Teams.logout()
    }, [])

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
        >
            <Grid item className={classes.banner}>
                <TeamsRemoveFigure />
            </Grid>
            <Grid item>
                <Typography variant="h2" className={classes.title} gutterBottom>
                    Are you sure you want to remove this tab?
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h2" className={classes.description} gutterBottom>
                    If you need help automating your spreadsheet work, contact us at {
                        <a 
                            href={SHEETGOSUPPORT}
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            support.sheetgo.com
                        </a>
                    }
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Remove


