import React, { useEffect } from 'react'
import * as msTeams from '@microsoft/teams-js'
import queryString from 'query-string'

import Api from '../../services/Api'
import CssBaseline from '@material-ui/core/CssBaseline'
import Preloader from '../components/Loader'
import { useSession } from '../../Context'

export default function Auth() {
    const { state: { session } } = useSession()

    // Gets params from URL in windows open by the app
    // and sends them to message listener implemented in components
    useEffect(() => {
        msTeams.initialize()
        const params = queryString.parse(window.location.search)
        params.origin = "ms_app"
        params.provider = "microsoft"
        const success = (response) => {
            try {
                msTeams.authentication.notifySuccess(response)
            } catch (e) {
                console.error('Error on send success Teams login')
            }
            try {
                window.Office.onReady().then(() => {
                    window.Office.context.ui.messageParent(JSON.stringify({ success: true, token: response }))
                })
            } catch (e) {
                console.error('Error on send success Excel login: ', e)
            }
        }
        const failure = (response) => {
            try {
                msTeams.authentication.notifyFailure({ error: true, data: response })
            } catch (e) {
                console.error('Error on send failure Teams login: ')
            }
            try {
                window.Office.onReady().then(() => {
                    window.Office.context.ui.messageParent(JSON.stringify({ success: false, msg: response }))
                })
            } catch (e) {
                console.error('Error on send failure Excel login', e)
            }
        }
        const SheetgoAPI = new Api({ token: session.token })

        if (session.email) {
            SheetgoAPI.setProvider(params, success, failure)
        } else {
            SheetgoAPI.getToken(params, success, failure)
        }
    }, [session])

    return (
        <CssBaseline>
            <Preloader />
        </CssBaseline>
    )
}

