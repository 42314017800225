import React, { useEffect, useState } from 'react'
import * as Teams from '../../services/Teams'
import * as Addin from '../../services/Addin'

import {
    Grid,
    Button,
    Typography
} from '@material-ui/core'

import Alert from '@material-ui/lab/Alert'

import { ReactComponent as TeamsFigure } from '../../images/microsoft-teams-login.svg'
import { ReactComponent as SheetgoLogo } from '../../images/logo/logo_vertical-for-white-background.svg'

import { useSession } from '../../Context'
import { setUser } from '../../Context/actions/session'
import SignUp from '../components/SignUp'
import AddinComponent from './Addin'
import { MSAppGTM } from '../../services/utils'

import useStyles from './styles'

const isOffice = (window.location.pathname === '/addin')

const Login = () => {
    
    const classes = useStyles()
    const { state: { session: { message } }, dispatch } = useSession()

    const [isNew, setIsNew] = useState(false)
    const [userSignUpObj, setUserSignUpObj] = useState({
        signUpToken: null,
        signUpEmail: null
    })

    useEffect(() => {
        MSAppGTM('MS Teams login Page')

        Teams.init()
    }, [])

    const onFinish = (tokenData) => {
        if (tokenData && 'signUpToken' in tokenData) {
            window.isNew = true
            setIsNew(true)
            setUserSignUpObj({
                signUpToken: tokenData.signUpToken,
                signUpEmail: tokenData.signUpEmail
            })
        } else if (tokenData) {
            dispatch(setUser(tokenData))
        }
    }

    const handleClick = async () => {
        MSAppGTM('Click button MS Teams login')

        if (isOffice) {
            Addin.openAuthenticationDialog(onFinish)
        } else {
            Teams.openAuthenticationDialog(onFinish)
        }
    }

    return (
        <Grid
            className={classes.container}
            container
        >
            <Grid item md={3} xs={12} />
            <Grid
                item
                container
                direction="column"
                justify="space-between"
                alignItems="center"
                xs={12}
                md={6}
            >
                <Grid
                    item
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                >
                    {message && (
                        <Alert className={classes.alert} severity="warning">{message}</Alert>
                    )}
                    <Grid justify="center" container item>
                        <SheetgoLogo className={classes.logo} />
                    </Grid>

                    {!isNew && (
                        <Grid justify="center" container item>
                            {!isOffice && (
                                <TeamsFigure className={classes.logoBanner} />
                            )}
                        </Grid>
                    )}
                </Grid>
                <Grid item>
                    {isNew ? (
                        <SignUp onFinish={onFinish} userData={userSignUpObj} />
                    ) : isOffice ? (
                        <AddinComponent onLogin={handleClick} />
                    ) : (
                        <>
                            <Typography variant="h2" className={classes.title} gutterBottom>
                                Connect spreadsheets, automate your work.
                            </Typography>
                            <Typography variant="body2" className={classes.bodyText}>
                                Create automated workflows to collect, manage and share data across your team
                            </Typography>
                        </>
                    )}
                </Grid>
                {(!isNew && !isOffice) && (
                    <Grid item>
                        <Button
                            onClick={handleClick}
                            variant="contained"
                            size="large"
                            color="primary"
                            className={isOffice ? classes.buttonAddin : classes.buttonTeams}
                        >
                            Login at Sheetgo
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Grid item md={3} xs={12} />
        </Grid>
    )
}


export default Login