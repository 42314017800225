import { makeStyles } from '@material-ui/core'


export default makeStyles(theme => ({
    root: {
        position: 'fixed',
        height: '100%',
        padding: theme.spacing(3, 14),
        textAlign: 'center',
        width: '100%',
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 2),
            overflowY: 'scroll'
        },
        '@media (max-height: 450px)': {
            padding: theme.spacing(1, 2.5)
        },
        '@media (max-height: 155px)': {
            padding: theme.spacing(1, 0)
        }
    },
    bannerContainer: {
        marginTop: `-${theme.spacing(2)}px`
    },
    banner: {
        width: '65%',
        pointerEvents: 'none',
        userDrag: 'none',
        userSelect: 'none',
        [theme.breakpoints.down('sm')]: {
            width: '70%'
        },
        '@media (max-height: 380px)': {
            maxWidth: 117,
            paddingBottom: theme.spacing(0),
        },
        '@media (max-height: 240px)': {
            maxWidth: 90
        },
        '@media (max-height: 180px)': {
            maxWidth: 55
        }
    },
    paragraph: {
        width: '65%',
        color: theme.palette.text.secondary,
        padding: theme.spacing(0, 0, 2),
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.1rem',
            width: '85%'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.0rem',
            width: '85%'
        },
        '@media (max-height: 300px)': {
            paddingBottom: 0,
            fontSize: '0.75rem'
        },
        '@media (max-height: 210px)': {
            fontSize: '0.6rem'
        }
    },
    authorizationButton: {
        color: '#fff',
        backgroundColor: ['#2962ff', '!important'],
        '@media (max-height: 300px)': {
            fontSize: '0.75rem'
        },
        '@media (max-height: 200px)': {
            fontSize: '0.6rem'
        },
        '@media (max-height: 155px)': {
            padding: theme.spacing(0.5, 1)
        }
    }
}))
