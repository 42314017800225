import React, { useEffect } from 'react'
import { sheetgoApiUrl } from '../constants/env'
import queryString from 'query-string'

import CssBaseline from '@material-ui/core/CssBaseline'

import Preloader from './components/Loader'

const Transition = () => {
    
    useEffect(() => {
        const params = queryString.parse(window.location.search)
        window.location = `${sheetgoApiUrl}/auth?provider=microsoft&ms_app=true${params.permission? ('&environment=consumers') : ''}`
    }, [])

    return (
        <>
            <CssBaseline>
                <Preloader />
            </CssBaseline>
        </>
    )
}

export default Transition