import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import { Avatar as AvatarBase } from '@material-ui/core'
import deepPurple from '@material-ui/core/colors/deepPurple'

import { getColorFromString } from '../../../services/colors' 


const useStyles = makeStyles(theme => ({
    rootSmall: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        fontSize: '0.875rem'
    },
    rootMedium: {
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
        fontSize: '1.15rem'
    },
    rootLarge: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        fontSize: '1.25rem'
    },
    rootColor: {
        backgroundColor: deepPurple[500]
    },
    rootSquare: {
        borderRadius: theme.spacing(1),
        fontSize: '1.1rem'
    }
}))

const getInitials = (name) => {
    const text = name.trim().replace(/[\\/"'-]/g, '').replace(/[\s&\s]/g, ' ').replace(/\s\s+/g, ' ')
    if (text.length < 3) return text.toUpperCase()
    const names = text.split(' ')
    if (names.length === 1) return names[0].substr(0, 2).toUpperCase()
    return names[0].charAt(0).concat(names[1].charAt(0)).toUpperCase()
}

export default function Avatar({ name, picture, size, square, children }) {
    const classes = useStyles()
    const componentClasses = {
        root: classNames(
            size === 'small' && classes.rootSmall,
            size === 'medium' && classes.rootMedium,
            size === 'large' && classes.rootLarge,
            square && classes.rootSquare
        )
    }
    return picture ? (
        <AvatarBase classes={componentClasses} alt={name} src={picture} />
    ) : children ? (
        <AvatarBase
            classes={componentClasses}
        >
            {children}
        </AvatarBase>
    ) : (
        <AvatarBase
            classes={componentClasses}
            style={{
                backgroundColor: getColorFromString(name),
            }}
        >
            {getInitials(name)}
        </AvatarBase>
    )
}

Avatar.propTypes = {
    name: PropTypes.string.isRequired,
    picture: PropTypes.string,
    size: PropTypes.oneOf([
        'small',
        'medium',
        'large'
    ]),
    square: PropTypes.bool,
    children: PropTypes.node
}

Avatar.defaultProps = {
    picture: null,
    size: 'medium',
    square: false,
    children: null
}
