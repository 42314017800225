import { makeStyles } from '@material-ui/core'

export const useMakeStyles = makeStyles(theme => ({
    iframe: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
}))