import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import { categoryProperties } from '../../../constants/workflow'
import Avatar from '../Avatar'


const useStyles = makeStyles(theme => ({
    icon: {
        flexShrink: 0,
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
        background: '#f95357',
        borderRadius: theme.spacing(3),
        padding: theme.spacing(0.5),
        boxSizing: 'border-box'
    },
    iconLarge: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        borderRadius: theme.spacing(1.5)
    },
    iconXLarge: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        borderRadius: theme.spacing(1.5)
    },
    iconSquare: {
        borderRadius: theme.spacing(1)
    }
}))

export default function WorkflowIcon({ name, category, size, square, children }) {
    const classes = useStyles()
    const data = categoryProperties(category)
    return data ? (
        <Grid
            className={classNames(
                classes.icon,
                size === 'large' && classes.iconLarge,
                size === 'xlarge' && classes.iconXLarge,
                square && classes.iconSquare
            )}
            style={{
                background: data.color
            }}
        >
            {data.icon}
        </Grid>
    ) : (
        <Avatar
            name={name}
            size={size}
            square={square}
        >
            {children && children}
        </Avatar>
    )
}

WorkflowIcon.propTypes = {
    name: PropTypes.string,
    category: PropTypes.string,
    size: PropTypes.oneOf([
        'small',
        'medium',
        'large',
        'xlarge'
    ]),
    square: PropTypes.bool,
    children: PropTypes.node
}

WorkflowIcon.defaultProps = {
    name: null,
    category: null,
    size: 'medium',
    square: false,
    children: null
}
