import { makeStyles } from '@material-ui/core'


export default makeStyles(theme => ({
    title: {
        margin: theme.spacing(2, 0, 3),
        fontSize: '1.6rem',
        color: '#1c272c',
        fontFamily: '"ProximaNovaWebBold", "Roboto", "Helvetica", "Arial", sans-serif',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.35rem',
            lineHeight: '1.5rem'
        }
    },
    bodyText: {
        marginBottom: theme.spacing(5),
        padding: theme.spacing(1),
        color: theme.palette.grey[600]
    },
    bodyTextContainer: {
        maxWidth: 300,
        padding: theme.spacing(0, 1)
    },
    signUpButton: {
        marginTop: theme.spacing(3),
        minWidth: 140
    },
    checkboxContainer: {
        marginLeft: 0,
        marginRight: 0,
        padding: theme.spacing(0, 1),
        maxWidth: 290
    }
}))
