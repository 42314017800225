import React, { useEffect } from 'react'

import {
    Typography,
    Grid,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from '@material-ui/core'

import DoneIcon from '@material-ui/icons/Done'

import LinkStyled from '../../components/LinkStyled'
import { GETHELPSIGNUP, WHINEEDACCOUNT } from '../../../constants/links'
import useStyles from './styles'
import { MSAppGTM } from '../../../services/utils'

const Addin = ({ onLogin }) => {

    const classes = useStyles()

    useEffect(() => {
        MSAppGTM('Addin Login Page')
    }, [])

    const handleLogin = () => {
        MSAppGTM('Click Button Login Addin Page')
        onLogin()
    }

    return (
        <Grid
            justify="space-around"
            direction="column"
            alignItems="center"
            container
        >
            <Typography variant="body2" className={classes.title}>
                To start connecting files, sign up to Sheetgo using your Microsoft log-in details.  With a Sheetgo account you can:
            </Typography>
            <List
                className={classes.container}
                dense
            >
                <ListItem >
                    <ListItemIcon
                        className={classes.itemIcon}
                    >
                        <DoneIcon />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.bodyText }} primary="Connect your online spreadsheets, even when your files are closed." />
                </ListItem>

                <ListItem>
                    <ListItemIcon
                        className={classes.itemIcon}
                    >
                        <DoneIcon />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.bodyText }} primary="Consolidate data from multiple files into a single worksheet." />
                </ListItem>

                <ListItem>
                    <ListItemIcon
                        className={classes.itemIcon}
                    >
                        <DoneIcon />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.bodyText }} primary="Distribute data from one worksheet to multiple other files." />
                </ListItem>
            </List>
            <Grid item>
                <Button
                    className={classes.buttonAddin}
                    variant="contained"
                    size="large"
                    onClick={handleLogin}
                    aria-label="Sign up"
                >
                    Sign up
                </Button>
            </Grid>
            <Grid 
                className={classes.container}
                item
            >
                <Typography
                    className={classes.option}
                >
                    Already using Sheetgo? {
                        <LinkStyled
                            onClick={handleLogin}
                            variant="primary"
                        >
                            Sign in
                        </LinkStyled>
                        }.
                </Typography>
                <Typography 
                    className={classes.option}
                >
                    Can’t sign up? {
                        <LinkStyled
                            target="_blank"
                            href={GETHELPSIGNUP}
                            variant="primary"
                        >
                            Get help here
                        </LinkStyled>
                        }.
                </Typography>
                <LinkStyled
                    className={classes.option}
                    href={WHINEEDACCOUNT}
                    target="_blank"
                    variant="primary"
                >
                    Why do I need a Sheetgo account?
                </LinkStyled>
            </Grid>
        </Grid>
    )
}

export default Addin