import { makeStyles } from '@material-ui/core/styles'


export default makeStyles(theme => ({
    container: {
        padding: theme.spacing(1, 0),
        height: '100%',
        width: '100%'
    },
    title: {
        fontSize: '1.4rem',
        fontWeight: 400,
        lineHeight: '1.8rem',
        textAlign: 'center',
        fontFamily: 'ProximaNovaWebBold, Helvetica, Arial, sans-serif',
        marginBottom: theme.spacing(4)
    },
    figure: {
        width: 260,
        height: 260
    },
    button: {
        height: 'auto',
        fontSize: '1.1rem',
        textTransform: 'initial',
        padding: theme.spacing(1.5, 4, 1.5, 2),
        borderRadius: theme.spacing(3.5),
        color: '#fff',
        backgroundColor: '#2962ff',
        '&:hover, &:focus': {
            backgroundColor: '#0b4ecb',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
            padding: theme.spacing(1, 3, 1, 1.5)
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.9rem'
        }
    },
    icon: {
        fontSize: 35,
        marginLeft: 2,
        marginRight: theme.spacing(1.5),
        color: '#fff'
    }
}))