import onecolor from 'onecolor'
import { padStart } from 'lodash'


export const getColorFromString = (str) => {
    let hash = 0
    str.split('').forEach(c => {
        hash = c.charCodeAt(0) + ((hash << 5) - hash)
    })
    hash = hash < 0 ? 360 - hash : hash
    return onecolor(`hsl(${hash % 360}, 70%, 60%)`).hex()
}

export const getColorWeight = (red, green, blue) => {
    return Number(
        String().concat(
            padStart(Math.floor(red * 255), 3, '0'),
            padStart(Math.floor(green * 255), 3, '0'),
            padStart(Math.floor(blue * 255), 3, '0')
        )
    )
}

export const rgbToHex = (rgb) => {
    const { red, green, blue } = rgb
    return onecolor(['RGB', red, green, blue, 1]).hex()
}

export const hexToRgb = (hex) => {
    const rgbArr = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16))
    return { red: rgbArr[0], green: rgbArr[1], blue: rgbArr[2] }
}
