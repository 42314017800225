import React, { useState } from 'react'
import * as Teams from '../../../services/Teams'

import {
    Typography,
    Fab,
    Grid
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import FigureWorkflowStartPNG from '../../../images/workflow/microsoft-teams-start.png'

import NewWorkflow from '../NewWorkflow/index'

import Api from '../../../services/Api'

import useStyles from './styles'
import { useSession } from '../../../Context'

export default function CreateWorkflowButton() {

    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [isFinished, setIsFinished] = useState(false)
    const [isNewWorkflow, setNewWorkflow] = useState(false)
    const { state: { session } } = useSession()

    const handleCreate = () => {
        setLoading(true)
        const SheetgoAPI = new Api({ token: session.token })
        SheetgoAPI.createWorkflow({ "name": "Untitled workflow" }, (data) => {
            setLoading(false)
            if (data) {
                Teams.saveWorkflowId(data.id, () => {
                    setIsFinished(true)
                })
                setNewWorkflow(true)
            }
        })
    }

    return (
        <Grid
            className={classes.container}
            container
            direction="column"
            justify="space-around"
            alignItems="center"
        >
            {!isNewWorkflow ? (
                <>
                    <Grid item>
                        <Grid justify="space-around" container>
                            <img src={FigureWorkflowStartPNG} alt="Start workflow" className={classes.figure} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title} variant="h1" align="center">
                            Connect your team’s spreadsheets
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Fab
                            className={classes.button}
                            onClick={handleCreate}
                            aria-label="Create Workflow"
                            variant="extended"
                            disabled={loading}
                        >
                            <AddIcon className={classes.icon} />
                            Create workflow
                        </Fab>
                    </Grid>
                </>
            ) : (
                <NewWorkflow isFinished={isFinished} />
            )}

        </Grid>
    )
}
