import { makeStyles } from '@material-ui/core'


export default makeStyles(theme => ({
    title: {
        margin: theme.spacing(1),
        fontWeight: 400,
        lineHeight: '1.8rem',
        fontSize: '1.4rem',
        textAlign: 'center',
        fontFamily: 'ProximaNovaWebBold, Helvetica, Arial, sans-serif',
    },
    logoBanner: {
        width: 260,
        height: 260
    },
    arrow: {
        right:25,
        bottom: 0,
        maxWidth: 60,
        position: 'absolute'
    }
}))