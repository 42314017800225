import React, { useEffect, createElement } from 'react'

import * as Teams from '../../services/Teams'
import * as Addin from '../../services/Addin'

import {
    Grid,
    Button,
    Typography
} from '@material-ui/core'

import { ReactComponent as OneDriveAuthorizationBanner } from '../../images/logo/authorization.svg'
import { MSAppGTM } from '../../services/utils'

import useStyles from './styles'

const isOffice = (window.location.pathname === '/addin')

const Permission = ({ callback }) => {

    const classes = useStyles()

    useEffect(() => {
        MSAppGTM('Permission Page has Shown')
        Teams.init()
    }, [])

    const onFinish = (tokenData) => {
        if('message' in tokenData) {
            console.log("message: ", tokenData.message)
        } else {
            if (callback)
                callback()
        }
    }

    const handleClick = async () => {
        MSAppGTM('Click Button Permission Page')

        if (isOffice) {
            Addin.openAuthenticationDialog(onFinish, true)
        } else {
            Teams.openAuthenticationDialog(onFinish, true)
        }
    }

    return (
        <Grid
            className={classes.root}
            spacing={1}
            alignItems="center"
            direction="column"
            justify="center"
            wrap="nowrap"
            container
        >
            <Grid item container justify="center" className={classes.bannerContainer}>
                {createElement(OneDriveAuthorizationBanner, {
                    className: classes.banner
                })}
            </Grid>
            <Grid item container justify="center">
                <Typography variant="body1" className={classes.paragraph}>
                    {`Sheetgo needs access to your OneDrive and SharePoint file storage to allow you to connect your spreadsheets.`}
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    onClick={handleClick}
                    variant="contained"
                    size="large"
                    className={classes.authorizationButton}
                >
                    Grant permission
                </Button>
            </Grid>
        </Grid>
    )
}


export default Permission