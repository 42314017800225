import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import onecolor from 'onecolor'
import { makeStyles } from '@material-ui/core/styles'

import { 
    ListItem, 
    ListItemIcon, 
    ListItemText
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        width: `calc(100% - ${theme.spacing(2)}px)`,
        minHeight: 54,
        position: 'relative',
        margin: [[6, theme.spacing(1)]],
        padding: theme.spacing(0, 1.5),
        borderRadius: 6,
        [theme.breakpoints.only('md')]: {
            width: `calc(100% - ${theme.spacing(1)}px)`,
            margin: theme.spacing(0.5),
            padding: theme.spacing(0, 1)
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: theme.spacing(0),
            padding: theme.spacing(0, 2)
        }
    },
    rootNotActive: {
        backgroundColor: ['#fff', '!important'],
        '&:active': {
            backgroundColor: [['rgba(0, 0, 0, 0.04)'], '!important']
        },
        '&:focus': {
            backgroundColor: [['rgba(0, 0, 0, 0.04)'], '!important']
        },
        '&:hover': {
            backgroundColor: [['rgba(0, 0, 0, 0.04)'], '!important'],
            '& $iconButtonsWrapper': {
                visibility: 'visible'
            }
        }
    },
    rootActive: {
        backgroundColor: onecolor(theme.palette.primary.main).alpha(.1).cssa(),
        '&:active': {
            backgroundColor: onecolor(theme.palette.primary.main).alpha(.1).cssa()
        },
        '&:focus': {
            backgroundColor: onecolor(theme.palette.primary.main).alpha(.1).cssa()
        },
        '&:hover': {
            backgroundColor: onecolor(theme.palette.primary.main).alpha(.1).cssa(),
            '& $iconButtonsWrapper': {
                visibility: 'visible'
            }
        }
    },
    icon: {
        minWidth: 40
    },
    iconActive: {
        color: theme.palette.primary.dark
    },
    textRoot: {
        margin: 0,
        padding: theme.spacing(1.5),
        overflow: 'hidden',
        width: '100%',
        [theme.breakpoints.only('md')]: {
            padding: theme.spacing(1.5, 0, 1.5, 1)
        },
        [theme.breakpoints.down('sm')]: {
            borderBottom: [[1, 'solid', theme.palette.grey[200]]]
        }
    },
    textPrimary: {
        lineHeight: '1.4'
    },
    textPrimaryActive: {
        color: theme.palette.primary.dark
    },
    textPrimaryWithColor: {
        color: 'inherit'
    },
    textSecondary: {
        fontSize: '0.8125rem'
    },
    iconButtonsWrapper: {
        visibility: 'hidden',
        [theme.breakpoints.only('md')]: {
            visibility: 'visible',
        },
        [theme.breakpoints.down('sm')]: {
            visibility: 'visible',
        }
    }
}))

export default function MenuItem({
    id,
    icon,
    primary,
    secondary,
    active,
    color,
    onClick
}) {

    const classes = useStyles()

    return (
        <>
            <ListItem
                classes={{
                    root: classNames(
                        classes.root,
                        !active && classes.rootNotActive,
                        active && classes.rootActive,
                    )
                }}
                style={color ? {
                    backgroundColor: onecolor(color).alpha(.15).cssa()
                } : {}}
                onClick={(e) => {
                    e.stopPropagation()
                    onClick(id)
                }}
                button
            >
                {icon && (
                    <ListItemIcon
                        classes={{
                            root: classNames(
                                classes.icon,
                                (active && !color) && classes.iconActive 
                            )
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                )}
                <ListItemText
                    primary={primary}
                    secondary={secondary}
                    classes={{
                        root: classes.textRoot,
                        primary: classNames(
                            classes.textPrimary,
                            active && (color
                                ? classes.textPrimaryWithColor
                                : classes.textPrimaryActive
                            )
                        ),
                        secondary: classes.textSecondary
                    }}
                    style={(color && active) ? {
                        color: onecolor(color).lightness(.32).cssa()
                    } : {}}
                />

            </ListItem>
        </>
    )
}

MenuItem.propTypes = {
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    primary: PropTypes.node.isRequired,
    secondary: PropTypes.string,
    active: PropTypes.bool,
    icon: PropTypes.element,
    color: PropTypes.string
}

MenuItem.defaultProps = {
    secondary: null,
    active: false,
    icon: null,
    color: null
}
