import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { sheetgoAppUrl } from '../../constants/env'

import CssBaseline from '@material-ui/core/CssBaseline'

import Login from '../Login'
import Preloader from '../components/Loader'

import { useSession } from '../../Context'
import { logOut } from '../../Context/actions/session'
import { MSAppGTM } from  '../../services/utils'

import { useMakeStyles } from '../../css'

const SheetgoPage = () => {

    const classes = useMakeStyles()

    const [workflowId, setWorkflowId] = useState(null)
    const [loading, setLoading] = useState(true)

    const { state: { session }, dispatch } = useSession()

    useEffect(() => {
        setLoading(true)
        const hashParams = queryString.parse(window.location.search)

        window.addEventListener('message', function (e) {
            if (e.data === 'logout') {
                dispatch(logOut())
            }
        }, false)

        if (session.token) {
            if (hashParams.workflowID) {
                setWorkflowId(hashParams.workflowID)
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }, [session, dispatch])

    const sheetgoURL = () => {
        MSAppGTM('Has Access App Sheetgo')

        const arrayUrl = [
            `${sheetgoAppUrl}/workflows/${workflowId}?`,
            `app=teams`,
            `session_token=${new Buffer(JSON.stringify(session)).toString('base64')}`,
            "stm_source=teams-app",
            "stm_medium=msapp",
            "stm_campaign=no-login-version"
        ]
        return arrayUrl.join('&').toString()
    }

    return (
        <>
            {loading ? (
                <CssBaseline>
                    <Preloader />
                </CssBaseline>
            ) : (
                    <>
                        {Boolean(workflowId) && Boolean(session.token) ? (
                            <iframe
                                className={classes.iframe}
                                title="Sheetgo"
                                frameBorder="0"
                                scrolling="no"
                                src={sheetgoURL()}
                            />
                        ) : (
                                <Login />
                            )}
                    </>
                )}
        </>
    )
}

export default SheetgoPage