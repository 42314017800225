import { makeStyles } from '@material-ui/core'


export default makeStyles(theme => ({
    title: {
        margin: theme.spacing(2, 0, 1),
        marginBottom: theme.spacing(2),
        fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif',
        lineHeight: '1.8rem',
        fontSize: '1.5rem',
        textAlign: 'center'
    },
    description: {
        fontSize: '1rem',
        textAlign: 'center',
        marginLeft: 50,
        marginRight: 50
    },
    button: {
        marginTop: 50
    },
    banner: {
        width: '100%',
        maxWidth: 500,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '@media (max-height: 800px)': {
            maxWidth: 300
        },
        '@media (max-height: 600px)': {
            maxWidth: 200
        }
    }
}))