import React, { useEffect, useState } from 'react'
import * as Teams from '../../services/Teams' 

import CssBaseline from '@material-ui/core/CssBaseline'

import Login from '../Login'
import Api from '../../services/Api'
import { useSession } from '../../Context'

import Preloader from '../components/Loader'
import ChoiceWorkflow from '../components/ChoiceWorkflow'

export default function Configure() {

    const [workflow, setWorkflow] = useState(null)
    const [loading, setLoading] = useState(true)

    const { state: { session } } = useSession()

    useEffect(() => {
        Teams.init()
        setLoading(true)
        if (session.token) {
            const SheetgoAPI = new Api({token: session.token})
            SheetgoAPI.getWorkflow((data) => {
                if(data) {
                    setWorkflow(data)
                }
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [session])

    return (
        <>
            {loading ? (
                <CssBaseline>
                    <Preloader />
                </CssBaseline>
            ) : (
                    workflow ? (
                        <>
                            <ChoiceWorkflow workflows={workflow} />
                        </>
                    ) : (
                            <Login />
                        )
                )}
        </>
    )
}