import { makeStyles } from '@material-ui/core'


export default makeStyles(theme => ({
    title: {
        margin: theme.spacing(2, 0, 1),
        lineHeight: '1.8rem',
        fontSize: '1.8rem',
        textAlign: 'center',
        fontFamily: 'ProximaNovaWebBold, Helvetica, Arial, sans-serif',
        '@media (max-height: 500px)': {
            margin: theme.spacing(1, 0, 0),
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem',
            lineHeight: '1.4rem',
            textAlign: 'center'
        }
    },
    bodyText: {
        marginTop: 30,
        marginBottom: 30,
        fontWeight: 400,
        fontSize: '1.2rem',
        textAlign: 'center',
        fontFamily: 'ProximaNovaWebRegular, Helvetica, Arial, sans-serif',
        color: theme.palette.grey[600],
        '@media (max-height: 500px)': {
            marginTop: 10,
            marginBottom: 10,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.85rem',
            textAlign: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    buttonTeams: {
        background: '#464775',
        color: 'white'
    },
    buttonAddin: {
        background: '#217346',
        color: 'white',
        '&:hover': {
            background: '#309d61'
        }
    },
    logo: {
        width: '100%',
        maxHeight: 60,
        maxWidth: 160,
        marginBottom: theme.spacing(2),
        '@media (max-height: 800px)': {
            maxWidth: 130,
            maxHeight: 90,
            marginBottom: theme.spacing(1),
        }
    },
    logoBanner: {
        width: '100%',
        maxWidth: 500,
        marginBottom: theme.spacing(2),
        '@media (max-height: 800px)': {
            maxWidth: 360
        },
        '@media (max-height: 600px)': {
            maxWidth: 300
        }
    },
    container: {
        marginTop: theme.spacing(3)
    },
    alert: {
        marginBottom: theme.spacing(2)
    }
}))