import React, { useEffect, useState } from 'react'

import Api from '../../services/Api'
import { sheetgoAppUrl } from '../../constants/env'
import { getFileFromUrl } from '../../services/Addin'
import CssBaseline from '@material-ui/core/CssBaseline'
import Login from '../Login'
import Permission from '../Permission'
import Preloader from '../components/Loader'

import messages from '../../constants/messages'

import { useMakeStyles } from '../../css'

import { logOut, setMessage } from '../../Context/actions/session'
import { setFile, removeFile } from '../../Context/actions/file'
import { useSession } from '../../Context'

const Addin = () => {

    const classes = useMakeStyles()
    const { state: { session, file }, dispatch } = useSession()

    const [permission, setPermission] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        window.addEventListener('message', function (e) {
            if (e.data === 'logout') {
                dispatch(logOut())
            }
        }, false)

        const fileInformation = getFileFromUrl()
        if (session.token && !file.id) {
            setLoading(true)
            const SheetgoAPI = new Api({ token: session.token })
            const payload = { 
                ...fileInformation, 
                ...{ 
                    isNew: window.isNew 
                } 
            }
            SheetgoAPI.getFileIdByPath(payload, (commingFile) => {
                setLoading(false)
                if (commingFile && 'message' in commingFile) {
                    if (commingFile.status === 1004) {
                        if (!permission)
                            setPermission(true)
                    } else {
                        dispatch(logOut())
                        dispatch(removeFile())
                        dispatch(setMessage(messages.fileNotFound))
                    }
                    console.log("message: ", commingFile.message)
                } else {
                    if (Object.keys(commingFile).length === 0) {
                        dispatch(logOut())
                        dispatch(removeFile())
                    }
                    dispatch(setFile(commingFile))
                }
            })
        } else {
            setLoading(false)
        }
    }, [session, dispatch, file, permission])

    const PermissionDone = () => {
        setPermission(false)
    }

    const getCurrentSheetgoUrl = () => {
        return [
            `${sheetgoAppUrl}/?app=addin`,
            `hostName=${file.name}`,
            `hostId=${file.id}`,
            `hostMimeType=${file.mimeType}`,
            `hostFolder=${file.folder}`,
            `provider=${file.provider}`,
            `session_token=${new Buffer(JSON.stringify(session)).toString('base64')}`,
            "stm_source=excel-addin",
            "stm_medium=iframe",
            "stm_campaign=no-login-version"
        ].join('&').trim()
    }

    return (
        <>
            {loading ? (
                <CssBaseline>
                    <Preloader />
                </CssBaseline>
            ) : (
                <>
                    {permission ? (
                        <Permission callback={PermissionDone} />
                    ) : (file && session.token) ? (
                        <iframe
                            className={classes.iframe}
                            title="Sheetgo"
                            frameBorder="0"
                            scrolling="no"
                            src={getCurrentSheetgoUrl()}
                        />
                    ) : (
                        <Login />
                    )}
                </>
            )}
        </>
    )
}

export default Addin