import React from 'react'

import { ReactComponent as AddinFigure } from '../../../images/office-addin-login.svg'
import { ReactComponent as ArrowFigure } from '../../../images/workflow/microsoft-teams-arrow.svg'

import {
    Grid,
    Typography
} from '@material-ui/core'

import useStyles from './styles'

const NewWorkflow = ({ isFinished }) => {
    const classes = useStyles()

    return (
        <Grid
            justify="space-between"
            alignItems="center"
            direction="column"
            container
        >
            <Grid item >
                <AddinFigure className={classes.logoBanner} />
            </Grid>
            <Grid item >
            <Typography variant="h2" className={classes.title} gutterBottom>
                Let’s go!
            </Typography>
            <Typography variant="h2" className={classes.title} gutterBottom>
                Click Save to start connecting.
            </Typography>
            </Grid>
            {!isFinished && (
                <ArrowFigure className={classes.arrow} />
            )}
        </Grid>
    )
}

export default NewWorkflow