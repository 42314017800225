import { makeStyles } from '@material-ui/core'


export default makeStyles(theme => ({
    title: {
        paddingTop: theme.spacing(5),
        fontSize: '1.1rem',
        fontWeight: 400,
        lineHeight: 1.2,
        maxWidth: 290,
        '@media (max-height: 600px)': {
            fontSize: '1.0rem',
            paddingTop: 0
        }
    },
    container: {
        textAlign: 'left',
        paddingTop: 24,
        width: 'calc(100% - 10px)',
        paddingLeft: '16px',
        maxWidth: 300
    },
    bodyText: {
        fontSize: '1.0rem !important',
        textAlign: 'left',
        fontWeight: 600,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        '@media (max-height: 600px)': {
            fontSize: '0.9rem !important'
        }
    },
    option: {
        marginTop: 8,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginBottom: 8,
        '@media (max-height: 600px)': {
            fontSize: '1.0rem',
            marginTop: theme.spacing(0)
        }
    },
    itemIcon: {
        minWidth: 42,
    },
    buttonAddin: {
        color: '#fff',
        fontSize: '1.4rem',
        padding: theme.spacing(0.6,12),
        borderRadius: theme.spacing(3.5),
        textTransform: 'initial',
        backgroundColor: '#2962ff',
        '&:hover, &:focus': {
            backgroundColor: '#0a3de6'
        }
    }
}))